import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa"; // Icons from react-icons
import { Container, Row, Col } from "react-bootstrap";

export function Footer() {
  return (
    <div>
      <footer id="contact" className="mt-3">
        <Container>
          <Row className="align-items-center">
            {/* Left side: Company name and social icons */}
            <Col md={6} className="d-flex flex-column align-items-start">
              <h3>Putzfee & Umzug </h3>
              <p className="fs-4">
                +49 176 633 741 51
                <br />
                Am Grünland 62
                <br />
                86169 Augsburg
              </p>
              <div>
                <a
                  href="https://facebook.com/profile.php?id=61566957057217"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark me-3"
                >
                  <FaFacebookF size={36} />
                </a>
                <a
                  href="https://instagram.com/putzfee.umzug"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark"
                >
                  <FaInstagram size={36} />
                </a>
              </div>
            </Col>

            {/* Right side: Google Maps */}
            <Col md={6} className="d-flex justify-content-end">
              <iframe
              className="responsive-map mb-3"
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2819.3261724583567!2d10.89496094142008!3d48.39865094457848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ebd76b268b343%3A0xedf75fa66bc6c655!2sAm%20Gr%C3%BCnland%2062%2C%2086169%20Augsburg!5e0!3m2!1sde!2sde!4v1728128214483!5m2!1sde!2sde"
                width="400"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}
